export default {
    computed: {
        cartItemsGroupedWithInsuranceProduct() {
            const cartItems = this.$store?.state?.cart?.cart?.items;
            if (Array.isArray(cartItems) && cartItems.length) {
                const parentProducts = cartItems.filter((item) => !item.insured_product_sku);
                const insuranceProducts = cartItems.filter((item) => item.insured_product_sku);
                let items = [];
                parentProducts.forEach((parentProduct) => {
                    items.push(parentProduct);
                    const insuranceProduct = insuranceProducts.find((insuranceProduct) => insuranceProduct.insured_product_sku === parentProduct.product.sku);
                    if (insuranceProduct) {
                        items.push(insuranceProduct);
                    }
                });
                return items;
            } else {
                return [];
            }
        },
    },
};
