//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    computed: {
        availableLocales() {
            return this.$i18n.locales.filter((i) => i.code !== this.$i18n.locale);
        },
    },
};
