import { mapGetters } from 'vuex';

export default {
    async fetch() {
        if (!this.cmsContent) {
            await this.$store.dispatch('cms/fetchAddOnProductInfo', { addOnProductType: this.addOnProductType });
        }
    },
    fetchOnServer: false,
    props: {
        addOnProductType: {
            type: String,
            required: true,
        },
    },
    computed: {
        ...mapGetters({
            insuranceProductInfo: 'cms/addOnProductsInfo',
        }),
        cmsContent() {
            return this.insuranceProductInfo(this.addOnProductType);
        },
        cmsTitle() {
            return this.cmsContent?.fields?.title;
        },
        cmsTopText() {
            const topItem = this.cmsContent?.fields?.items?.find((item) => item?.fields?.title === 'top');
            return topItem?.fields?.content;
        },
        cmsImage() {
            const topItem = this.cmsContent?.fields?.items?.find((item) => item?.fields?.title === 'top');
            return topItem?.fields?.image?.fields?.file || {};
        },
        cmsBottomText() {
            const bottomItem = this.cmsContent?.fields?.items?.find((item) => item?.fields?.title === 'bottom');
            return bottomItem?.fields?.content;
        },
    },
};
